import { AuthConfig } from "angular-oauth2-oidc";
export const authConfig: AuthConfig = {
	issuer: "https://sso.boost-epargne.com/auth/realms/boostepargne",
	redirectUri: window.location.origin + "/dashboard",
	clientId: "angular-client",
	dummyClientSecret: "ai1qsTW6ygXrgVIKHB1OtyWICtFb9gHB",
	scope: "openid profile email",
	responseType: "code",
	showDebugInformation: true,
	strictDiscoveryDocumentValidation: false
};
