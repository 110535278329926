import { Injectable, inject } from "@angular/core";
import { gql } from "@apollo/client/core";
import { authConfig } from "@src/auth.config";
import { OAuthService, OAuthSuccessEvent } from "angular-oauth2-oidc";
import { Apollo } from "apollo-angular";

import { ActivatedRoute } from "@angular/router";
import { UserService } from "@services/API/user.service";
import {
	BehaviorSubject,
	Observable,
	ReplaySubject,
	combineLatest,
	of
} from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { delay, first, switchMap, tap } from "rxjs/operators";
import { BudgetinsightService } from "./API/budgetinsight.service";
import { StoreService } from "./store.service";
@Injectable({ providedIn: "root" })
export class ConnectionService {
	private BI = inject(BudgetinsightService);
	private USER = inject(UserService);
	private APOLLO = inject(Apollo);
	private OAUTH = inject(OAuthService);
	private route = inject(ActivatedRoute);
	private STORE = inject(StoreService);
	private css = "color:#d35400";

	private _isDoneLoadingSubject = new ReplaySubject<boolean>();
	public isDoneLoading$ = this._isDoneLoadingSubject
		.asObservable()
		.pipe(delay(100));
	private _isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
	public isAuthenticated$ = this._isAuthenticatedSubject.asObservable();

	public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
		this.isAuthenticated$,
		this.isDoneLoading$,
		of(this.OAUTH.hasValidAccessToken())
	]).pipe(
		map(values => values.every(b => b)),
		tap(canActivate => {
			if (!this._isAuthenticatedSubject.getValue()) {
				this.OAUTH.revokeTokenAndLogout();
			}
		})
	);

	public auth$: Observable<boolean> = this.OAUTH.events.pipe(
		map(e => e instanceof OAuthSuccessEvent),
		tap(isAuth => this._isAuthenticatedSubject.next(isAuth))
	);

	public authentification() {
		console.log("authentification: ");
		this.OAUTH.configure(authConfig);

		this.OAUTH.loadDiscoveryDocumentAndLogin()
			.then(ok => {
				this._isDoneLoadingSubject.next(true);
				if (ok) this.initConnections();
			})
			.catch(error => {
				this._isDoneLoadingSubject.next(true);
			});

		this.OAUTH.setupAutomaticSilentRefresh();
	}

	public initConnections() {
		console.groupCollapsed("%cINIT CONNECTIONS", this.css);

		// 1. Set user profile & get Bi token
		this.USER.init()
			.pipe(
				switchMap(biToken =>
					!!biToken ? this.knownUser(biToken) : this.anonymousUser()
				),
				first()
			)
			.subscribe({
				next: connections => {
					return console.groupEnd();
				},
				error: err => {
					console.error(err);
					console.groupEnd();
				}
			});
	}

	private anonymousUser() {
		console.log("%cinit BI connection : utilisateur annonyme", this.css);
		// check URI for code param
		// if code param,  setBiToken
		// refresh user profile
		// connection_id

		const uriCode = this.route.snapshot.paramMap.get("code");
		const code = Array.isArray(uriCode) ? uriCode[uriCode.length - 1] : uriCode;

		if (code) {
			return this.BI.getPermanentTokenFromTemp(code).pipe(
				switchMap((auth_token: string) => this.setBiToken(auth_token)),
				tap(() => this.BI.refresh())
			);
		} else {
			return of(null);
		}
	}

	private knownUser(biToken: string) {
		console.log("%cinit BI connection : utilisateur connu", this.css);
		this.STORE.loadTips();
		this.STORE.loadContracts();
		this.STORE.loadConnections();

		// init BI service
		this.BI.token = biToken;
		return of(this.BI.refresh());
	}
	public logOut() {
		console.log("%cLOGOUT", "color:red");
		this.OAUTH.logOut();
	}

	private setBiToken(access_token: string) {
		return this.APOLLO.mutate({
			mutation: gql`
				mutation setBiToken($access_token: String!) {
					setBiToken(access_token: $access_token)
				}
			`,
			variables: {
				access_token
			}
		});
	}
}
